import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import DefaultView from "../DefaultView";
import "./index.scss";


export default function Login(props) {
  var isSafari = false
  console.log(navigator.userAgent)
  if (navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i) 
                || navigator.userAgent.match(/iPad/i) 
                || navigator.userAgent.match(/iPod/i)
  )
  {
    isSafari = true  
  }
  else if (browserName === "Safari")
  {
    isSafari = true
  }
  console.log(browserName)
  sessionStorage.clear();
  const navigate = useNavigate();
  // React States
  const [isError, setIsError] = useState(false);

  const fetchPost = (uname,pass) =>  
    {
      const url ="https://dental-user.azurewebsites.net/user-service/api/authentication/";

        const postData =  {
          userEmail: uname,
          password: pass
      }
        return axios.post(url,postData)
            .then(response => {
                return response;
            }).then(data=>{
                return data;
            }).catch(error=>{
              if(error.response.status===401){
                return Promise.reject("Access Denied. Username or Password incorrect");
              }else{
                return Promise.reject(error);
              }
            })
    }

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    fetchPost(uname.value,pass.value).then(response=>{
      sessionStorage.setItem("token",response.data.userToken);
      sessionStorage.setItem("workBookName",response.data.workBookName);
      navigate("/DetailedView");
    }).catch((error)=>{
      setIsError(true);
    });
  };

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="input-container">
          <img src="username.png" className="uicon"></img>
          <input type="text" name="uname" required placeholder="Username" autoComplete="off"/>
        </div>
        <div className="input-container">
        <img src="password.png" className="pass-icon"></img>
          <input type="password" name="pass" required placeholder="Password" autoComplete="off"/>
        </div>
        {isError ? <div className="error">Access Denied</div> :  null}
        <div className="button-container">
          <input type="submit" value="Log In"/>
        </div>
      </form>
    </div>
  );

  return (
    isSafari ? <DefaultView/> :
    <div className="login-page">
      <div className="logo">
        <img src="Dental-Analytics_Logo.png" alt="logo" className="logoImage"/>
      </div>
      <div className="login-form">
        <div className="title">Log In</div>
        {renderForm}
      </div>
      <a href="mailto:brian@dentalanalytics.co.uk" className="contact-us">
        <img src="mail.png" className="mail-icon"></img>
        <span>Contact Us</span>
      </a>
    </div>
  );
}