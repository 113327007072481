import React, { useState } from "react";
import axios from 'axios';
import "./index.scss";


export default function DefaultView() {
  return (
    <div className="login-page">
      <div className="logo">
        <img src="Dental-Analytics_Logo.png" alt="logo" className="logoImage"/>
      </div>
      <p className="title">This website is compatible only with regular tabs of Google Chrome,Microsoft Edge and Mozilla Firefox. </p>
      <p className="title">Please do not use Safari or incognito/private browsing tabs.</p>
    </div>
  );
}