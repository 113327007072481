import React from "react";
import {BrowserRouter, Routes,Route } from "react-router-dom";
import Login from "./components/Login";
import DetailedView from "./components/DetailedView";
import "./App.css";
export default function App () {
  return(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>}></Route>
      <Route exact path="/DetailedView" element={<DetailedView/>}></Route>
    </Routes>
  </BrowserRouter>
  );
}