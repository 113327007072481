import React, { Component } from 'react';
import { browserName } from "react-device-detect";
import DefaultView from "../DefaultView";
let viz;
class TableauContainer extends Component {
    render() {
        let token = sessionStorage.getItem("token");
        let workBookName = sessionStorage.getItem("workBookName");

        const finalUrl = 'https://dub01.online.tableau.com/t/dentalanalytics/views/'+workBookName+'/LandingPage?:embed=y&:display_count=n&:origin=viz_share_link&:showVizHome=n  width="1500" height="10"';
        const isSafari = browserName === "Safari";
            return (
                isSafari ? <DefaultView/> : 
                <div className="KTDashboard-home d-flex home-page-header kt-home-screen" width = "100%">
                    <tableau-viz id="tableauViz" src={finalUrl} toolbar='hidden' tabs="no" width ="100%" height = "100vh" token = {token}>
                    </tableau-viz>
                </div>
            );
    }
}

export default TableauContainer;
